<script>
import Form, { formCreate } from '../../../../../components/form';
import DetailInfo from './components/detail.vue';

formCreate.component('sfaVisitAbnormalDetail', DetailInfo);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
  },
  async created() {
    await this.getFormRule('sfaCenter_visitManage_abnormalEport_check');
    this.setValue({
      detail: this.formConfig.row,
    });
  },
};
</script>
