<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="客户编码">
          <el-input v-model="clientCode" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="客户名称">
          <el-input v-model="clientName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="客户类型">
          <el-input v-model="clientTypeDesc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="人员姓名">
          <el-input v-model="visitRealName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="人员职位">
          <el-input v-model="visitPosName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属组织">
          <el-input v-model=" visitOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="异常描述">
          <el-input v-model="exceptionDetail" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="提交时间">
          <el-input v-model="exceptionTime" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="照片">
          <el-image
            v-for="(item,index) in photoList"
            :preview-src-list="photoList"
            :src="item"
            :key="index"
          ></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          clientCode: '', // 客户编码
          clientName: '', // 客户名称
          clientTypeDesc: '', // 客户类型
          visitRealName: '', // 拜访人员姓名
          visitPosName: '', // 拜访人员职位
          visitParentOrgName: '', // 所属区域
          visitOrgName: '', // 所属办事处
          exceptionDetail: '', // 异常描述
          exceptionTime: '', // 提交时间
          photoList: [], // 图片 list
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.clientCode = val.clientCode;// 客户编码
          this.clientName = val.clientName;// 客户名称
          this.clientTypeDesc = val.clientTypeDesc;// 客户类型
          this.visitRealName = val.visitRealName;// 拜访人员姓名
          this.visitPosName = val.visitPosName;// 拜访人员职位
          this.visitParentOrgName = val.visitParentOrgName;// 所属区域
          this.visitOrgName = val.visitOrgName;// 所属办事处
          this.exceptionDetail = val.exceptionDetail;// 异常描述
          this.exceptionTime = val.exceptionTime;// 提交时间
          val.visitExceptionPics.forEach((item) => {
            if (item.urlPathPrefix && item.urlPath) {
              this.photoList.push(item.urlPathPrefix + item.urlPath);
            }
          });
        }
      },
    },
  },
  data() {
    return {
      clientCode: '', // 客户编码
      clientName: '', // 客户名称
      clientTypeDesc: '', // 客户类型
      visitRealName: '', // 拜访人员姓名
      visitPosName: '', // 拜访人员职位
      visitParentOrgName: '', // 所属区域
      visitOrgName: '', // 所属办事处
      exceptionDetail: '', // 异常描述
      exceptionTime: '', // 提交时间
      photoList: [], // 图片
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }
  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
