var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "100px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户编码" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.clientCode,
                      callback: function ($$v) {
                        _vm.clientCode = $$v
                      },
                      expression: "clientCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.clientName,
                      callback: function ($$v) {
                        _vm.clientName = $$v
                      },
                      expression: "clientName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户类型" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.clientTypeDesc,
                      callback: function ($$v) {
                        _vm.clientTypeDesc = $$v
                      },
                      expression: "clientTypeDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.visitRealName,
                      callback: function ($$v) {
                        _vm.visitRealName = $$v
                      },
                      expression: "visitRealName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员职位" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.visitPosName,
                      callback: function ($$v) {
                        _vm.visitPosName = $$v
                      },
                      expression: "visitPosName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属组织" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.visitOrgName,
                      callback: function ($$v) {
                        _vm.visitOrgName = $$v
                      },
                      expression: " visitOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "异常描述" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.exceptionDetail,
                      callback: function ($$v) {
                        _vm.exceptionDetail = $$v
                      },
                      expression: "exceptionDetail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提交时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.exceptionTime,
                      callback: function ($$v) {
                        _vm.exceptionTime = $$v
                      },
                      expression: "exceptionTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "照片" } },
                _vm._l(_vm.photoList, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    attrs: { "preview-src-list": _vm.photoList, src: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }